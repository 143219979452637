import { createStore } from 'vuex'
const store = createStore({
    state() {
        return {
            token: '',
            login: false
        }
    },
})

export default store