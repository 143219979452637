<template>
    <v-card
    class="mx-auto"
    >
        <template v-slot:title>
        {{project_data.title}}
        </template>

        <v-card-text>
        {{project_data.content}}
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    props: {
        project_data: {
            type: Object,
            default() {
                return {}
            }
        }
    },
}
</script>